:root {
    --color-primary: #ff5100;
    --color-titles: #EEEEEE;
    --color-subtitles: #acacac;
    /* --color-body-dark: #15191f; */
    --color-body-dark: #15191f;
    --color-body-light: #3c4653;
    --color-body: linear-gradient(to bottom, #222831, var(--color-body-dark));
    
    --shadow-main: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

::selection {
    background: #6b3131;
}

* {
    font-display: swap;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    background: var(--color-body);
    background-attachment: fixed;
}

html {
    scroll-behavior: smooth;
}

body {
    margin-top: 5rem;
}

h2,
h3,
ul,
p {
    margin: 0;
}

ul {
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

section {
    position: relative;
}

@media screen and (max-width: 768px) {
    body {
        margin-top: unset;
    }
}