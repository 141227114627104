nav {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 5rem;
	font-size: 1.25rem;
    font-family: 'Poppins', sans-serif;
    transition: background 0.3s ease-in-out, top 0.4s ease-in-out;
    color: var(--color-titles);
    background: var(--color-body-dark);
    box-shadow: var(--shadow-main);
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.navlink__container {
    padding: 1.5rem 2rem;
    cursor: pointer;
    border-radius: 0.5rem;
}

.navlink__container:hover {
    background: var(--color-body-light);
}

nav .activeClass {
    color: var(--color-primary);
}

nav.transparent {
    background: transparent;
    box-shadow: none;
}

nav.hide {
    top: -5rem;
}

@media screen and (max-width: 768px) {
    nav {
        display: none;
    }
    /* nav {
        width: 30rem;
        height: 20rem;
    }

    .navbar {
        flex-direction: column;
        height: 20rem;
    }

    nav.transparent {
        background: unset;
    } */
} 
